import React from "react";
import styled from "styled-components";
import { Link } from "gatsby";
import { v4 } from "uuid";
import PhoneIcon from "../Icons/PhoneIcon";
import WidthIcon from "../../images/width.svg";
import HeightIcon from "../../images/height.svg";
import LenthIcon from "../../images/lenth.svg";
import QouteIcon from "../Icons/QouteIcon";
import { Badge } from "../section";
import Img from "gatsby-image";
import DefaultButton from "../button/DefaultButton";
import DefaultOutlineButton from "../button/DefaultOutlineButton";
import BreakpointUp from "../Media/BreakpointUp";
import BreakpointDown from "../Media/BreakpointDown";

const Card = styled.div`
  margin-bottom: 30px;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  background: #fff;
  box-shadow: 0px 12px 32px rgba(0, 0, 0, 0.2);
  position: relative;
  flex-direction: column;
`;
const CardHeading = styled.div`
  padding: 0 0 20px;
  margin: 0 0 20px;
  border-bottom: 1px solid #e5e5e5;
  text-align: center;
  ${BreakpointUp.lg`		
		text-align:left;
	`}
`;
const CardTitle = styled(Link)`
  margin: 0 0 5px;
  color: #002337;
  font-size: 24px;
  line-height: 34px;
  font-weight: 700;
  letter-spacing: -0.5px;
`;
const CardFigure = styled.div`
  position: relative;
  width: 100%;
  ${BreakpointUp.md`		
		& .gatsby-image-wrapper{
			height:100%;
		}
	`}
  & .gatsby-image-wrapper {
    img {
      object-fit: inherit !important;
    }
  }
`;
const CardContent = styled.div`
  padding: 30px 15px;
  position: relative;
  z-index: 1;
  width: 100%;
  ${BreakpointUp.lg`	
		padding:60px 25px 25px 25px;
	`}
`;
const CardGrid = styled.div`
  display: flex;
  flex-wrap: wrap;
  margin-right: -5px;
  margin-left: -5px;
`;
const CardGridLeft = styled.div`
  position: relative;
  width: 100%;
  min-height: 1px;
  padding-right: 5px;
  padding-left: 5px;
  ${BreakpointUp.lg`
		flex-basis: 0;
    flex-grow: 1;
    max-width: 100%;
	`}
`;
// const CardGridRight = styled.div`
//   position: relative;
//   width: 100%;
//   min-height: 1px;
//   padding-right: 5px;
//   padding-left: 5px;
//   ${BreakpointUp.lg`
// 		flex: 0 0 auto;
//     width: auto;
//     max-width: 100%;
// 	`}
// `;
const CardOption = styled.ul`
  margin: 0 -15px;
  list-style: none;
  padding: 0;
  ${BreakpointDown.sm`
		font-size:14px;
	`}
`;
const CardOptionItem = styled.li`
  display: inline-block;
  font-size: 12px;
  margin: 0 15px;
  color: #6c7780;
  font-weight: 500;
  position: relative;
  &:before {
    display: inline-block;
    content: "";
    width: 6px;
    height: 6px;
    background-color: #676b79;
    border-radius: 50%;
    position: absolute;
    left: -15px;
    top: 50%;
    transform: translateY(-50%);
  }
  &:first-child {
    &:before {
      display: none;
    }
  }
`;
// const PriceBlock = styled.div`
//   display: flex;
//   align-items: center;
//   justify-content: space-between;
//   ${BreakpointUp.lg`
// 		flex-direction: column;
// 	`}
//   & small {
//     display: block;
//     font-size: 14px;
//     color: #999;
//     font-weight: 500;
//     line-height: 24px;
//     text-align: right;
//   }
// `;
// const TotalPrice = styled.div`
//   margin-bottom: 15px;
//   & strong {
//     display: block;
//     font-family: "Fira Sans", serif;
//     font-weight: 700;
//     color: #0a4e82;
//     font-size: 28px;
//     line-height: 36px;
//     text-align: right;
//   }
// `;
// const DepositPrice = styled.div`
//   margin-bottom: 15px;
//   & strong {
//     font-family: "Fira Sans", serif;
//     font-weight: 500;
//     font-size: 20px;
//     color: #6c7780;
//     display: block;
//     text-align: right;
//   }
// `;
const ButtonToolbar = styled.div`
  display: flex;
  justify-content: center;
  margin: 0 -10px 20px -10px;
  ${BreakpointDown.lg`
	display:block;
	`}
  & a, & .quote {
    margin: 0 10px;
    ${BreakpointDown.lg`
			display:block;
			+ a, + .quote{
				margin-top:15px
			}
			& .btn, & .quote{
				display:block;
				width:100%;
			}
		`}
  }
  .quote {
    &:hover {
      cursor: pointer;
    }
  }
`;
const HelpBlock = styled.p`
  color: #999;
  margin: 0;
  line-height: 20px;
  text-align: center;
  font-size: 11px;
  ${BreakpointUp.lg`
		font-size: 12px;
	`}
`;
const ProductSize = styled.div`
  background-color: #fff;
  border: 1px solid #e5e5e5;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 15px;
  ${BreakpointUp.lg`	
		width: 100%;
		max-width: 376px;
		position:absolute;
		transform:translateX(-50%);		
		margin-top: -30px;
		margin-bottom:0;
		left: 50%;
		top: 0;
		right: 0;
		z-index: 1;
	`}
`;
const ProductItemSize = styled.div`
  background-color: #fff;
  padding: 9px 15px;
  border-left: 1px solid #e5e5e5;
  border-top: none;
  text-align: center;
  overflow: hidden;
  ${BreakpointUp.sm`
		display: flex;
		align-items: center;
	`}
  &:first-child {
    border-left: none;
  }
  .icon {
    height: 30px;
    + .text {
      ${BreakpointUp.sm`
				margin-left:8px;
			`}
    }
  }
  .text {
    font-size: 12px;
    color: #6c7780;
    font-weight: 500;
    white-space: nowrap;
    line-height: 20px;
    text-align: left;
    display: flex;
    align-items: center;
    ${BreakpointUp.sm`
			display:block;
		`}
    b {
      display: block;
      font-size: 18px;
      font-weight: 700;
      color: #231f20;
      line-height: 20px;
      margin-left: 5px;
      ${BreakpointUp.sm`
				margin-left:0;
			`}
    }
  }
`;
const ProductCard = ({ data, showQuote }) => {
  return (
    <Card className="card">
      {data.popularLabel === "Good" ? (
        <Badge color="#fff" bgColor="#0A4E82" bgAfterColor="#003258">
          {data.popularLabel}
        </Badge>
      ) : (
        <></>
      )}
      {data.popularLabel === "Better" ? (
        <Badge color="#fff" bgColor="#FFAA00" bgAfterColor="#DC6700">
          {data.popularLabel}
        </Badge>
      ) : (
        <></>
      )}
      {data.popularLabel === "Best" ? (
        <Badge color="#fff" bgColor="#0BA200" bgAfterColor="#087400">
          {data.popularLabel}
        </Badge>
      ) : (
        <></>
      )}
      <CardFigure className="card-img">
        <Link to={data.url}>
          <Img fluid={data.image.fluid} alt={data.image.title} />
        </Link>
      </CardFigure>
      <CardContent className="card-body">
        <ProductSize className="measurement">
          <ProductItemSize>
            <span className="icon">
              <img src={WidthIcon} alt="width" />
            </span>
            <span className="text">
              Width <b>{data.width}</b>
            </span>
          </ProductItemSize>
          <ProductItemSize>
            <span className="icon">
              <img src={LenthIcon} alt="length" />
            </span>
            <span className="text">
              Length <b>{data.length}</b>
            </span>
          </ProductItemSize>
          <ProductItemSize>
            <span className="icon">
              <img src={HeightIcon} alt="height" />
            </span>
            <span className="text">
              Height <b>{data.height}</b>
            </span>
          </ProductItemSize>
        </ProductSize>
        <CardGrid>
          <CardGridLeft>
            <CardHeading className="card-heading">
              <CardTitle to={data.url}>{data.productName}</CardTitle>
              {data.featuresImage && (
                <CardOption>
                  {data.featuresImage.map((item) => {
                    return (
                      <CardOptionItem key={v4()}>{item.title}</CardOptionItem>
                    );
                  })}
                </CardOption>
              )}
            </CardHeading>
          </CardGridLeft>
          {/* <CardGridRight>
            <PriceBlock>
              <TotalPrice>
                <strong>${data.totalPrice}</strong>
                <small>Starting Price</small>
              </TotalPrice>
              {data.depositPrice ? (
                <DepositPrice>
                  <strong>${data.depositPrice}</strong>
                  <small>Down Payment</small>
                </DepositPrice>
              ) : (
                <></>
              )}
            </PriceBlock>
          </CardGridRight> */}
        </CardGrid>

        <ButtonToolbar>
          <a href="tel:8773505464">
            <DefaultButton icon={<PhoneIcon />} text="Call Us Now" />
          </a>
          <div
            className="quote"
            role="button"
            onClick={showQuote}
            onKeyDown={showQuote}
          >
            <DefaultOutlineButton icon={<QouteIcon />} text="Get a Quote" />
          </div>
        </ButtonToolbar>
        <HelpBlock>{data.priceDescription}</HelpBlock>
      </CardContent>
    </Card>
  );
};

export default ProductCard;
